import { TCustomError } from '../context/error-handler-context';
import i18n from '../i18n/config';
import { IsoCurrencyCode, LoanDto, LoanDtoInstallmentDto } from '../types/api';

export const parseErrorMessage = (error: TCustomError): string | null => {
  return typeof error === 'string' || error == null ? error : error.error;
};

const accumulateTotalForDay = (
  targetInstallments: Array<LoanDtoInstallmentDto>,
  referenceInstallment: LoanDtoInstallmentDto
): number => {
  const todayInstallments = targetInstallments.filter(
    (instalment) =>
      new Date(instalment.dueDate as string).toDateString() ===
      new Date(referenceInstallment.dueDate as string).toDateString()
  );

  const totalAmount = todayInstallments.reduce(
    (total, current) => total + (current.total ? Number(current.total) : 0),
    0
  );

  const paidAmount = todayInstallments.reduce(
    (total, current) =>
      total + (current.paidTotal ? Number(current.paidTotal) : 0),
    0
  );

  return totalAmount - paidAmount;
};

/**
 * This method returns the closest installment to display, with OVERDUE or PLANNED state
 */

interface IClosestInstallment {
  dueToDate: string;
  accumulatedTotal: number;
  currency: IsoCurrencyCode;
}

export const calculateAmountToPayForInstallments = (
  activeLoans: Array<LoanDto>
): IClosestInstallment | null => {
  if (
    !activeLoans ||
    (Array.isArray(activeLoans) && activeLoans.length === 0)
  ) {
    return null;
  }

  const currentDate = new Date();
  const plannedInstallments: Array<LoanDtoInstallmentDto> = [];
  const overdueInstallments: Array<LoanDtoInstallmentDto> = [];

  activeLoans.forEach((loan) => {
    loan.instalments?.forEach((instalment) => {
      if (instalment.state === 'PLANNED') {
        plannedInstallments.push(instalment);
      } else if (instalment.state === 'OVERDUE') {
        overdueInstallments.push(instalment);
      }
    });
  });

  let accumulatedTotal = 0;
  let installmentToDisplay: LoanDtoInstallmentDto | null = null;

  if (overdueInstallments.length > 0) {
    const oldestOverdueInstalment = overdueInstallments.reduce(
      (oldest, current) => {
        return new Date(current.dueDate as string) <
          new Date(oldest?.dueDate as string) &&
          new Date(current.dueDate as string) >= currentDate
          ? current
          : oldest;
      }
    );

    if (oldestOverdueInstalment) {
      installmentToDisplay = oldestOverdueInstalment;
      accumulatedTotal = accumulateTotalForDay(
        overdueInstallments,
        oldestOverdueInstalment
      );
    }
  } else {
    const earliestPlannedInstallment = plannedInstallments.reduce(
      (earliest, current) =>
        new Date(current.dueDate as string) <
        new Date(earliest?.dueDate as string)
          ? current
          : earliest
    );

    if (earliestPlannedInstallment) {
      installmentToDisplay = earliestPlannedInstallment;
      accumulatedTotal = accumulateTotalForDay(
        plannedInstallments,
        earliestPlannedInstallment
      );
    }
  }

  return {
    dueToDate: installmentToDisplay?.dueDate ?? '',
    accumulatedTotal,
    currency: (installmentToDisplay?.currency ?? 'CZK') as IsoCurrencyCode,
  };
};

export const generateRandomNonce = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const safeParseJSON = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error('Error parsing JSON', error);
    return null;
  }
};

export const getPolicyDocumentUrl = (): string => {
  const policyDocument = i18n.language === 'cs' ? 'ochrana' : 'privacypolicy';
  const { protocol, hostname, port } = window.location;

  const documentUrl = `${protocol}//${hostname}${
    port ? `:${port}` : ''
  }/${policyDocument}.pdf`;

  return documentUrl;
};
