import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';

import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import { useLoading } from '../../context/loading-context';
import { useErrorHandler } from '../../context/error-handler-context';
import axios from '../../utils/axios';
import styles from './Settings.module.scss';
import { ExternalUserDto, UserType } from '../../types/api';
import { useRole } from '../../hooks';

interface ISettingsProps {
  currentUser: ExternalUserDto;
  customerId?: string;
}

const Settings: FC<ISettingsProps> = ({ currentUser }) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();
  const role = useRole();

  const [consentGiven, setConsentGiven] = useState<boolean>(
    Boolean(currentUser.consentGiven)
  );

  const SettingsSwitch = styled((props: SwitchProps) => <Switch {...props} />)(
    () => ({
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          color: '#FF4F90',

          '& + .MuiSwitch-track': {
            opacity: 0.5,
            backgroundColor: '#FF4F90',
          },
        },
      },
    })
  );

  const handleChangeConsentGiven = () => {
    const method = consentGiven ? 'delete' : 'post';
    const url = `parties/${currentUser.partyId}/consent`;

    setLoading(true);
    axios({
      method,
      url,
    })
      .then((res) => {
        setLoading(false);
        setConsentGiven((prevState) => !prevState);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <div className={styles['settings-wrapper']}>
      <h2>{t('settings-personal-data')}</h2>
      <div className={styles['personal-wrapper']}>
        <div className={styles.line}>
          <span className={styles.label}>{t('settings-name')}</span>
          <span className={styles.value}>{currentUser.name}</span>
        </div>
        <div className={styles.line}>
          <span className={styles.label}>
            {t('settings-email')} <span>({t('settings-login')})</span>
          </span>
          <span className={styles.value}>{currentUser.email}</span>
        </div>
        <div className={styles.line}>
          <span className={styles.label}>{t('settings-phone')}</span>
          <span className={styles.value}>{currentUser?.phone ?? ''}</span>
        </div>
      </div>

      {role === UserType.PARTNER && (
        <>
          <h2>{t('settings-company-data')}</h2>
          <div className={styles['personal-wrapper']}>
            <div className={styles.line}>
              <span className={styles.label}>{t('settings-regnum')}</span>
              <span className={styles.value}>
                {currentUser.partner?.regNum ?? ''}
              </span>
            </div>
            <div className={styles.line}>
              <span className={styles.label}>
                {t('settings-account-number')}
              </span>
              <span className={styles.value}>
                {currentUser.partner?.accountNumber ?? ''}
              </span>
            </div>
          </div>
        </>
      )}

      {role === UserType.CUSTOMER && (
        <>
          <h2>{t('settings-notifications')}</h2>
          <div className={styles['notifications-wrapper']}>
            <div className={styles.line}>
              <span className={styles.label}>
                {t('settings-consent-given')}
              </span>
              <span className={styles.value}>
                <SettingsSwitch
                  defaultChecked={consentGiven}
                  onChange={handleChangeConsentGiven}
                />
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Settings;
