import { FormControl, InputLabel, MenuItem } from '@mui/material';
import IBAN from 'iban';

import { styled } from '@mui/material/styles';
import { FC, useState, FormEvent } from 'react';
import { BankDto, IsoCurrencyCode } from '../../types/api';
import TextEdit from '../UI/TextEdit';
import { useTranslation } from 'react-i18next';
import styles from '../Dialogs/IbanSubmission.module.scss';
import Select, { SelectProps, SelectChangeEvent } from '@mui/material/Select';
import Button from '../UI/Button';
import { TCustomError } from '../../context/error-handler-context';

interface IIbanSubmissionProps {
  title: string;
  currency: string;
  handleBankSubmit: (data: { iban: string }) => void;
  setError: (err: TCustomError) => void;
  banks: Array<BankDto>;
  isPsdConnection?: boolean;
}

const SelectStyled = styled((props: SelectProps) => <Select {...props} />)(
  () => ({
    '.MuiSelect-select': {
      color: '#838383',
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 600,
      fontSize: '1rem',
    },

    fieldset: {
      border: '1px solid #939393',
    },
  })
);

const IbanSubmission: FC<IIbanSubmissionProps> = ({
  title,
  currency,
  handleBankSubmit,
  banks,
  setError,
  isPsdConnection,
}) => {
  const { t } = useTranslation();
  const [accNumber, setAccNumber] = useState('');
  const [iban, setIban] = useState<string>('');
  const [prefix, setPrefix] = useState('');
  const [code, setCode] = useState('');

  const handleBankChange = (event: SelectChangeEvent<unknown>) => {
    setCode(event.target.value as string);
  };

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();

    const error = t('application-bank-incorrect-acc');
    let finalIban = '';

    try {
      if (currency === IsoCurrencyCode.CZK) {
        const fixedPrefix = ('000000' + prefix).slice(-6);
        const fixedAccNumber = ('0000000000' + accNumber).slice(-10);
        const bban = `${fixedPrefix}-${fixedAccNumber}/${code}`;
        const bankValidator = require('cz-bank-account-validator/lib/cz-bank-account-validator');

        if (!bankValidator.validate(bban)) {
          throw new Error(error);
        }

        finalIban = IBAN.fromBBAN(
          'CZ',
          `${code} ${fixedPrefix} ${fixedAccNumber}`
        );
      } else {
        if (!IBAN.isValid(iban)) {
          throw new Error(error);
        }

        finalIban = iban;
      }

      handleBankSubmit({ iban: finalIban });
    } catch (err) {
      setError(error);
    }
  };

  return (
    <form
      onSubmit={submitHandler}
      className={isPsdConnection ? styles['is-psd'] : styles['is-application']}
    >
      <div className={styles['form-wrapper']}>
        {currency === IsoCurrencyCode.CZK ? (
          <>
            <div className={styles['form-block']}>
              <span>{title}</span>

              <div className={styles['bank-acc']}>
                <div className={styles.prefix}>
                  <TextEdit
                    id="prefix"
                    label={t('application-bank-acc-prefix')}
                    value={prefix}
                    type="text"
                    onChange={(value: string) => {
                      const val = value.replace(/[^\d]/g, '');
                      setPrefix(val);
                    }}
                  />
                </div>
                <span>-</span>
                <TextEdit
                  autoFocus={true}
                  id="accNumber"
                  label={t('application-bank-acc-number')}
                  value={accNumber}
                  type="text"
                  required
                  onChange={(value: string) => {
                    const val = value.replace(/[^\d]/g, '');
                    setAccNumber(val);
                  }}
                />
              </div>
            </div>
            <div className={styles['form-block']}>
              <span className={styles['select-title']}>
                {t('application-bank-select')}
              </span>

              <FormControl required fullWidth>
                <InputLabel id="banks-select-label">
                  {t('application-bank-option')}
                </InputLabel>
                <SelectStyled
                  labelId="banks-select-label"
                  label={`${t('application-bank-option')} *`}
                  value={code}
                  onChange={handleBankChange}
                  variant="outlined"
                >
                  {banks.map((b) => {
                    return (
                      <MenuItem key={b.code} value={b.code}>
                        {`${b.code} - ${b.name}`}
                      </MenuItem>
                    );
                  })}
                </SelectStyled>
              </FormControl>
            </div>
          </>
        ) : (
          <TextEdit
            autoFocus={true}
            id="iban"
            label="IBAN"
            value={iban}
            type="text"
            required
            onChange={(value) => {
              setIban(value.replace(/\s+/g, ''));
            }}
            fullWidth
          />
        )}

        {isPsdConnection ? (
          <div className={styles['form-block']}>
            <p>{t('application-psd-txt')}</p>
            <p>{t('application-psd-txt2')}</p>
          </div>
        ) : null}

        <div className={styles.button}>
          <Button type="submit" className="application">
            {t('application-button-continue')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default IbanSubmission;
