
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';


export const StyledPin = styled(TextField)`
  width: 2.8125rem;
  height: 3.4375rem;

  input {
    text-align: center;
  }

  @media (max-width: 400px) {
    width: 2.5rem;
    height: 2.75rem;
    input {
      padding: 0.75rem 0.25rem;
    }
  }
`;
