import { useState, FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import i18n from '../../i18n/config';

import footerLogo from '../../assets/footer-logo.svg';
import telIcon from '../../assets/icons/tel-icon.svg';
import emailIcon from '../../assets/icons/email-icon.svg';

import styles from './Footer.module.scss';
import {
  FLOWPAY_SUPPORT_EMAIL,
  FLOWPAY_SUPPORT_PHONE_NUMBER,
} from '../../constants/flowpayDefaults';

interface IFooterProps {
  hideContacts?: boolean;
  transparent?: boolean;
  isFixedPosition?: boolean;
}

const Footer: FC<IFooterProps> = ({
  hideContacts,
  transparent,
  isFixedPosition,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  //@ts-ignore TODO update the library to fix typing issues
  const defLang = i18n.fpLangs.find((lang) => lang.code === i18n.language);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setLanguage = (lang: string) => {
    if (defLang.code !== lang) {
      i18n.changeLanguage(lang);
      localStorage.setItem('flowpay_lang', lang);
    }
    handleClose();
  };

  return (
    <footer
      className={`${transparent ? styles.transparent : ''} ${
        isFixedPosition ? styles.fixed : ''
      }`}
    >
      {process.env.REACT_APP_MULTILANG_ENABLED === 'TRUE' && (
        <Tooltip title={defLang.label}>
          <button className={styles.language} onClick={handleClick}>
            <img src={defLang.flag} alt={defLang.label} />
          </button>
        </Tooltip>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/*//@ts-ignore TODO update the library to fix typing issues */}
        {i18n.fpLangs.map((lang) => {
          return (
            <MenuItem
              key={lang.code}
              selected={defLang.code === lang.code}
              onClick={() => setLanguage(lang.code)}
            >
              <img
                style={{ width: '24px' }}
                className={styles.language}
                src={lang.flag}
                title={lang.label}
                alt={lang.label}
              />
            </MenuItem>
          );
        })}
      </Menu>

      {!hideContacts && (
        <>
          <span>{t('footer-info')}</span>
          <div className={styles['contact-info']}>
            <img
              src={telIcon}
              title={FLOWPAY_SUPPORT_PHONE_NUMBER}
              alt={FLOWPAY_SUPPORT_PHONE_NUMBER}
            />
            <a href={`tel:${FLOWPAY_SUPPORT_PHONE_NUMBER}`}>
              {FLOWPAY_SUPPORT_PHONE_NUMBER}
            </a>
          </div>
          <div className={styles['contact-info']}>
            <img
              src={emailIcon}
              title={FLOWPAY_SUPPORT_EMAIL}
              alt={FLOWPAY_SUPPORT_EMAIL}
            />
            <a href={`mailto:${FLOWPAY_SUPPORT_EMAIL}`}>
              {FLOWPAY_SUPPORT_EMAIL}
            </a>
          </div>
        </>
      )}

      <div className={styles.logo}>
        <img src={footerLogo} title="Footer logo" alt="Footer logo" />
      </div>
    </footer>
  );
};

export default Footer;
