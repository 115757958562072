import { FC, useState, FormEvent, KeyboardEvent } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

import { FormWrapper } from '../FormWrapper';
import { TUserRegisterData } from '../registrationSchema';
import { RegistrationSteps } from '../../../../constants/registrationSteps';

interface IWebsitesProps {
  registrationData: TUserRegisterData;
  updateRegistrationData: (fields: Partial<TUserRegisterData>) => void;
  moveToStep: (step: RegistrationSteps) => void;
}

const urlRegex = /^(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]+$/;

export const Websites: FC<IWebsitesProps> = ({
  updateRegistrationData,
  registrationData,
  moveToStep,
}) => {
  const [web, setWeb] = useState<string>('');
  const [hasNoWeb, setHasNoWeb] = useState<boolean>(false);
  const [websitesError, setWebsitesError] = useState<string>('');
  const { t } = useTranslation();

  const updateWebsites = (newWebsite: string) => {
    if (!newWebsite) {
      updateRegistrationData({ websites: [] });
    } else {
      updateRegistrationData({
        websites: [...registrationData.websites, newWebsite],
      });
    }
  };

  const handleHasWebChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setHasNoWeb(isChecked);
    if (isChecked) {
      updateWebsites('');
      setWeb('');
      setWebsitesError('');
    }
  };

  const handleSubmitWeb = (event: FormEvent) => {
    event.preventDefault();
    if (registrationData.websites.some((website) => website === web)) {
      setWebsitesError('Your website is already included');
    } else if (!urlRegex.test(web)) {
      setWebsitesError(t('registration.validation.website-incorrect'));
    } else if (web.trim() !== '') {
      updateWebsites(web);
      setWebsitesError('');
      setWeb('');
    }
  };

  const handleWebsiteRemove = (indexToRemove: number) => {
    updateRegistrationData({
      websites: registrationData.websites.filter(
        (_, index) => index !== indexToRemove
      ),
    });
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmitWeb(event);
    }
  };

  const isFormValid = (): boolean => {
    let error = '';
    let isValid = true;
    if (!registrationData.websites.length && !hasNoWeb) {
      isValid = false;
      error = t('registration.validation.website-required');
    }
    setWebsitesError(error);

    return isValid;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      moveToStep(RegistrationSteps.ICO);
    }
  };

  return (
    <FormWrapper
      onSubmit={onSubmit}
      title={t('registration.registration-websites-header')}
      footer={t('registration.registration-websites-footer')}
      currentStep={2}
    >
      <Box display="flex" justifyContent="flex-start" gap={2}>
        <TextField
          autoFocus
          type="text"
          size="small"
          placeholder="seznam.cz"
          sx={{ mr: 2, width: '250px' }}
          value={web}
          onChange={(e) => setWeb(e.target.value)}
          disabled={hasNoWeb}
          onKeyPress={handleKeyPress}
          error={Boolean(websitesError)}
          helperText={websitesError}
        />
        <Box>
          <IconButton onClick={handleSubmitWeb} color="primary">
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        gap={2}
        flexDirection="column"
        mt={3}
      >
        {registrationData.websites.length > 0 ? (
          <Paper
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              p: 0.5,
              m: 0,
              mt: 2,
              gap: 1,
            }}
            component="ul"
          >
            {registrationData.websites.map((website, index) => {
              let icon;

              return (
                <Chip
                  icon={icon}
                  label={website}
                  onDelete={() => handleWebsiteRemove(index)}
                  key={index}
                />
              );
            })}
          </Paper>
        ) : null}
        <FormControlLabel
          control={<Checkbox value={hasNoWeb} onChange={handleHasWebChanged} />}
          label={t('registration.registration-no-web')}
        />
      </Box>
    </FormWrapper>
  );
};
