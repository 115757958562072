import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FC, ReactElement } from 'react';

import { FormWrapper } from '../FormWrapper';
import { TUserRegisterData } from '../registrationSchema';
import { PartnerPartnerCategory } from '../../../../types/api';
import { RegistrationSteps } from '../../../../constants/registrationSteps';
import { useTranslation } from 'react-i18next';

interface IBusinessProps {
  business: PartnerPartnerCategory;
  updateRegistrationData: (fields: Partial<TUserRegisterData>) => void;
  title: string;
  moveToStep: (step: RegistrationSteps) => void;
  footer: ReactElement;
}

export const Business: FC<IBusinessProps> = ({
  business,
  updateRegistrationData,
  title,
  moveToStep,
  footer,
}) => {
  const onSubmit = () => {
    moveToStep(RegistrationSteps.Websites);
  };
  const { t } = useTranslation();
  return (
    <FormWrapper
      title={title}
      currentStep={1}
      onSubmit={onSubmit}
      footer={footer}
    >
      <Select
        value={business}
        onChange={(e) =>
          updateRegistrationData({
            business: e.target.value as PartnerPartnerCategory,
          })
        }
        size="small"
        sx={{ width: '200px' }}
      >
        <MenuItem value={PartnerPartnerCategory.GASTRO}>
          {t('registration.business-options.gastro')}
        </MenuItem>
        <MenuItem value={PartnerPartnerCategory.ECOMMERCE}>
          {t('registration.business-options.ecommerce')}
        </MenuItem>
        <MenuItem value={PartnerPartnerCategory.PPC}>
          {t('registration.business-options.ppc')}
        </MenuItem>
        <MenuItem value={PartnerPartnerCategory.OTHER}>
          {t('registration.business-options.other')}
        </MenuItem>
      </Select>
    </FormWrapper>
  );
};
