import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { Provider as ReduxProvider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { init as initApm } from '@elastic/apm-rum';

import { store } from './store';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import { LoadingProvider } from './context/loading-context';
import { ErrorHandlerProvider } from './context/error-handler-context';
import i18n from './i18n/config';
import { isJsonString } from './utils/string';
import App from './App';
import Vop from './Vop';
import IndicativeOffer from './IndicativeOffer';
import Error from './components/Pages/Error';
import './index.css';
import { theme } from './theme';
import Snackbars from './components/UI/Snackbars';
import Dialogs from './components/Dialogs/Dialogs';

const pathname = window.location.pathname;
let slugs = pathname.split('/');
slugs = slugs.filter(Boolean);

const keyName = 'flowpay_lang';
let lang = localStorage.getItem(keyName);

const search = window.location.search.substring(1);
let queryObj: Record<string, unknown> = {};
if (search) {
  queryObj = JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === '' ? value : decodeURIComponent(value);
    }
  );
}

initApm({
  serviceName: 'myFlowpay',
  //environment: process.env.REACT_APP_FP_ENV,
  serverUrl:
    'https://426da55542154f44a9cecd3269e0d750.apm.westeurope.azure.elastic-cloud.com:443',
});

const utmKeyName = 'utm_params';
const {
  utm_source,
  utm_campaign,
  utm_medium,
  utm_content,
  utm_id,
  utm_term,
  referrer,
} = queryObj;
const utm =
  utm_source ||
  utm_campaign ||
  utm_medium ||
  utm_content ||
  utm_id ||
  utm_term ||
  referrer;
if (utm) {
  const newUtm = {
    date: new Date(),
    utm_campaign,
    utm_medium,
    utm_source,
    utm_content,
    utm_id,
    utm_term,
    referrer,
  };

  const localUtm = localStorage.getItem(utmKeyName);
  if (!localUtm || !isJsonString(localUtm)) {
    localStorage.setItem(utmKeyName, JSON.stringify([newUtm]));
  } else {
    const lastDate = Date.now() / 1000 - 30 * 24 * 60 * 60;

    let utmArr = JSON.parse(localUtm);
    if (Array.isArray(utmArr)) {
      utmArr = utmArr.filter((u) => {
        const t = new Date(u.date).getTime() / 1000;
        return t > lastDate;
      });

      utmArr.push(newUtm);
      localStorage.setItem(utmKeyName, JSON.stringify(utmArr));
    } else {
      localStorage.setItem(utmKeyName, JSON.stringify([newUtm]));
    }
  }
}

if (
  queryObj &&
  queryObj.lang &&
  //@ts-ignore
  i18n.fpLangs.find((l) => l.code === queryObj.lang)
) {
  localStorage.setItem(keyName, queryObj.lang as string);
  i18n.changeLanguage(queryObj.lang as string);
  //@ts-ignore
} else if (lang && i18n.fpLangs.find((l) => l.code === lang)) {
  i18n.changeLanguage(lang);
}

let baseComponent = <App />;
if (slugs) {
  switch (slugs[0]) {
    case 'vop':
      baseComponent = <Vop />;
      break;

    case 'indicative-offer':
      baseComponent = <IndicativeOffer />;
      break;

    default:
      break;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        hideIconVariant
      >
        <Router>
          <Auth0ProviderWithHistory>
            <ErrorHandlerProvider>
              <LoadingProvider>
                <ErrorBoundary FallbackComponent={Error}>
                  {baseComponent}
                  <Snackbars />
                  <Dialogs />
                </ErrorBoundary>
              </LoadingProvider>
            </ErrorHandlerProvider>
          </Auth0ProviderWithHistory>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  </ReduxProvider>
);
