import { useState, useEffect, useCallback, FormEvent, FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useLoading } from '../../../context/loading-context';
import { useErrorHandler } from '../../../context/error-handler-context';
import axios from '../../../utils/axios';

import TextEdit from '../../UI/TextEdit';
import Button from '../../UI/Button';

import styles from './Application.module.scss';
import { ProductParams, ExternalUserDto } from '../../../types/api';
import { COLORS } from '../../../constants/colors';
import { getPolicyDocumentUrl } from '../../../utils/utils';

interface IRep1SubmitData {
  customerId: string;
  rep1Id?: string;
  offerId: string;
  leadId: string;
  product: ProductParams;
}

interface IApplicationRep1Props {
  customerId: string;
  leadId: string;
  offerId: string;
  product: ProductParams;
  currentUser: ExternalUserDto;
  handleRep1Submit: (data: IRep1SubmitData) => void;
}

const ApplicationRep1: FC<IApplicationRep1Props> = ({
  currentUser,
  customerId,
  handleRep1Submit,
  leadId,
  offerId,
  product,
}) => {
  const { t } = useTranslation();

  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const [rep, setRep] = useState({
    firstName: '',
    lastName: '',
    regNum: '',
    email: '',
    phone: '',
  });
  const [consentGiven, setConsentGiven] = useState(false);

  const loadData = useCallback(() => {
    setLoading(true);

    Promise.all([
      axios.get(`customers/${customerId}`),
      axios.get(
        `customers/${customerId}/representatives/${currentUser.userId}`
      ),
    ])
      .then((res) => {
        const data = {
          regNum: res[0].data.organization.regNum,
          ...res[1].data,
        };

        setRep(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [setLoading, customerId, currentUser.userId, setError]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();

    if (!consentGiven) {
      setError(t('application-rep1-error-consent'));
      return;
    }

    const data = {
      customerId: customerId,
      rep1Id: currentUser.userId,
      offerId,
      leadId,
      product,
    };
    handleRep1Submit(data);
  };

  return (
    <div>
      <div className="gradient info">
        <h1>
          <span>{t('application-rep1-title')}</span>
        </h1>
      </div>
      <div className={styles['body']}>
        <p>
          <Trans
            i18nKey="application-rep1-txt"
            components={[<strong></strong>]}
          ></Trans>
        </p>
        <form onSubmit={submitHandler}>
          <div className={styles['form-wrapper']}>
            <TextEdit
              id="firstName"
              label={t('application-rep1-firstname')}
              value={rep.firstName}
              disabled
            />
            <TextEdit
              id="lastName"
              label={t('application-rep1-lastname')}
              value={rep.lastName}
              disabled
            />
            <TextEdit
              id="regNum"
              label={t('application-rep1-regnum')}
              value={rep.regNum}
              disabled
            />
            <TextEdit
              id="phone"
              label={t('application-rep1-phone')}
              value={rep.phone}
              disabled
            />
            <TextEdit
              id="email"
              label={t('application-rep1-email')}
              value={rep.email}
              disabled
            />
          </div>
          <div className={styles['consent-given']}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={consentGiven}
                  name="consentGiven"
                  onChange={() => setConsentGiven(!consentGiven)}
                  sx={{
                    color: COLORS.text,
                    mr: '.5rem',
                    '& .MuiSvgIcon-root': { fontSize: 28, color: COLORS.text },
                  }}
                />
              }
              label={
                <>
                  {t('application-rep1-consent')}{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://my.flowpay.io/vop"
                  >
                    {t('application-rep1-consent2')}
                  </a>{' '}
                  {t('application-rep1-consent3')}{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={getPolicyDocumentUrl()}
                  >
                    {t('application-rep1-consent4')}
                  </a>
                </>
              }
              sx={{
                '> span': {
                  fontFamily: '"Inter", sans-serif',
                  fontWeight: '300',
                },
              }}
            />
          </div>
          <div className={styles['form-wrapper']}>
            <div className={styles.button}>
              <Button
                type="submit"
                className="application"
                id="app_rep1_confirmed"
              >
                {t('application-button-continue')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplicationRep1;
