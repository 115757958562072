// TODO: import only used functions
import moment from 'moment';

type StringOrNumberOrDate = string | number | Date;
type Language = 'cz' | 'en';

const monthNames = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  cz: [
    'Leden',
    'Únor',
    'Březen',
    'Duben',
    'Květen',
    'Červen',
    'Červenec',
    'Srpen',
    'Září',
    'Říjen',
    'Listopad',
    'Prosinec',
  ],
};

export function getDatesInRange(startDate: Date, endDate: Date) {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export function getMonth(date: StringOrNumberOrDate) {
  const newDate = new Date(date);
  return newDate.getMonth() + 1;
}

export function getToday() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
}

export function getFirstDayPreviousMonth() {
  const date = new Date();
  const prevMonth = date.getMonth() - 1;
  const firstDay = 1;

  return new Date(date.getFullYear(), prevMonth, firstDay);
}

export function formatDate(date?: StringOrNumberOrDate, format = 'YYYY-MM-DD') {
  if (!date) return '';
  return moment(date).format(format);
}

export function addMonth(date: StringOrNumberOrDate) {
  return moment(date).add(1, 'M').toDate();
}

export function moveDate(
  date: StringOrNumberOrDate,
  amount: string | number,
  unit?: moment.unitOfTime.DurationConstructor,
  ignoreTime = false
) {
  const d = moment(date).add(amount, unit);
  if (ignoreTime) return d.startOf('day').toDate();
  return d.toDate();
}

export function getMonthNameByIndex(
  month: number,
  language: Language = 'cz'
): string {
  return monthNames[language][month];
}

export function getDateDiff(
  date1: StringOrNumberOrDate,
  date2: StringOrNumberOrDate,
  unitOfTime: moment.unitOfTime.Diff = 'days'
) {
  const d1 = moment(date1);
  const d2 = moment(date2);

  return d2.diff(d1, unitOfTime);
}

export const calculateRemainingPinValidationTime = (
  targetTimestamp: string
): number => {
  const targetMoment = moment(targetTimestamp);
  const currentMoment = moment();
  const duration = moment.duration(targetMoment.diff(currentMoment));

  return Math.floor(duration.asSeconds());
};

export const displayRemainingTime = (seconds: number): null | string => {
  if (!seconds || seconds <= 0) {
    return null;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};
