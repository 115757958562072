import i18n from 'i18next';
import { IsoCurrencyCode } from '../types/api';

export const ProductsArr = [
  { code: 'M1', months: 1, txt: `1 ${i18n.t('common-month')}` },
  { code: 'M3', months: 3, txt: `3 ${i18n.t('common-months-alt')}` },
  { code: 'M6', months: 6, txt: `6 ${i18n.t('common-months')}` },
  { code: 'M12', months: 12, txt: `12 ${i18n.t('common-months')}` },
];

// const Product = Object.freeze({
//   W1: Symbol('W1'),
//   W2: Symbol('W2'),
//   M1: Symbol('M1'),
//   M3: Symbol('M3'),
//   M6: Symbol('M6'),
//   M12: Symbol('M12'),
// });

export const ProductPeriod = Object.freeze({
  W1: Symbol('1_week'),
  W2: Symbol('2_week'),
  M1: Symbol('1_month'),
  M3: Symbol('3_month'),
  M6: Symbol('6_month'),
  M12: Symbol('12_month'),
});

export const InstallmentPeriod = Object.freeze({
  Week: Symbol('Week'),
  Month: Symbol('Month'),
});

export const getProductCatalogue: any = (currency = IsoCurrencyCode.CZK) => {
  const anonymousOfferMaxAmount =
    currency === IsoCurrencyCode.CZK ? 1000000 : 40000;

  return {
    M1: {
      id: 'M1',
      text: `1 ${i18n.t('common-month')}`,
      typProduktu: ProductPeriod.M1,
      baloon: true,
      installmentPeriod: InstallmentPeriod.Month,
      interestRateMultiplier: 1,
      installmentCount: 1,
      postponeEnabled: false,
      prolongationEnabled: true,
      prolongationFeeMultiplier: 1,
      prolongationFeeMultiplierCF: 0,
      anonymousOfferMaxAmount: anonymousOfferMaxAmount,
      anonymousOfferRatePerc: 1.5,
    },
    M3: {
      id: 'M3',
      text: `3 ${i18n.t('common-months-alt')}`,
      typProduktu: ProductPeriod.M3,
      baloon: true,
      installmentPeriod: InstallmentPeriod.Month,
      interestRateMultiplier: 1,
      installmentCount: 3,
      postponeEnabled: false,
      prolongationEnabled: true,
      prolongationFeeMultiplier: 1,
      prolongationFeeMultiplierCF: 0,
      anonymousOfferMaxAmount: anonymousOfferMaxAmount,
      anonymousOfferRatePerc: 1.75,
    },
    M6: {
      id: 'M6',
      text: `6 ${i18n.t('common-months')}`,
      typProduktu: ProductPeriod.M6,
      baloon: false,
      installmentPeriod: InstallmentPeriod.Month,
      interestRateMultiplier: 1,
      installmentCount: 6,
      postponeEnabled: true,
      postponeFeeMultiplier: 1,
      postponeFeeMultiplierCF: 0,
      prolongationEnabled: false,
      anonymousOfferMaxAmount: anonymousOfferMaxAmount,
      anonymousOfferRatePerc: 1,
    },
    M12: {
      id: 'M12',
      text: `12 ${i18n.t('common-months')}`,
      typProduktu: ProductPeriod.M12,
      baloon: false,
      installmentPeriod: InstallmentPeriod.Month,
      interestRateMultiplier: 1,
      installmentCount: 12,
      postponeEnabled: true,
      postponeFeeMultiplier: 1,
      postponeFeeMultiplierCF: 0,
      prolongationEnabled: false,
      anonymousOfferMaxAmount: anonymousOfferMaxAmount,
      anonymousOfferRatePerc: 1,
    },
  };
};

export const ProductCatalogue = {
  // W1: {
  // 	id: Product.W1,
  // 	installmentPeriod: InstallmentPeriod.Week,
  // 	installmentPeriodCount: 1,
  //   interestRateMultiplier: 0.25,
  //   installmentCount: 1,
  // 	installmentCountCF: 1,
  // 	postponeEnabled: false,
  // 	postponeFeeMultiplier: 1,
  // 	postponeFeeMultiplierCF: 1,
  // 	prolongationEnabled: false,
  // 	prolongationFeeMultiplier: 1,
  // },
  // W2: {
  // 	id: Product.W2,
  // 	installmentPeriod: InstallmentPeriod.Week,
  // 	installmentPeriodCount: 2,
  //   interestRateMultiplier: 0.5,
  //   installmentCount: 1,
  // 	installmentCountCF: 1,
  // 	postponeEnabled: false,
  // 	postponeFeeMultiplier: 1,
  // 	postponeFeeMultiplierCF: 1,
  // 	prolongationEnabled: false,
  // 	prolongationFeeMultiplier: 1,
  // },
  M1: {
    // id: Product.M1,
    id: 'M1',
    // text: '1 měsíc',
    text: `1 ${i18n.t('common-month')}`,
    typProduktu: ProductPeriod.M1,
    baloon: true,
    installmentPeriod: InstallmentPeriod.Month,
    // installmentPeriodCount: 1,
    interestRateMultiplier: 1,
    installmentCount: 1,
    // installmentCountCF: 2,
    postponeEnabled: false,
    // postponeFeeMultiplier: 2,
    // postponeFeeMultiplierCF: 1,
    prolongationEnabled: true,
    prolongationFeeMultiplier: 1,
    prolongationFeeMultiplierCF: 0,
  },
  M3: {
    // id: Product.M3,
    id: 'M3',
    // text: '3 měsíce',
    text: `3 ${i18n.t('common-months-alt')}`,
    typProduktu: ProductPeriod.M3,
    baloon: true,
    installmentPeriod: InstallmentPeriod.Month,
    // installmentPeriodCount: 3,
    interestRateMultiplier: 1,
    installmentCount: 3,
    // installmentCountCF: 4,
    postponeEnabled: false,
    // postponeFeeMultiplier: 2,
    // postponeFeeMultiplierCF: 1,
    prolongationEnabled: true,
    prolongationFeeMultiplier: 1,
    prolongationFeeMultiplierCF: 0,
  },
  M6: {
    // id: Product.M6,
    id: 'M6',
    // text: '6 měsíců',
    text: `6 ${i18n.t('common-months')}`,
    typProduktu: ProductPeriod.M6,
    baloon: false,
    installmentPeriod: InstallmentPeriod.Month,
    // installmentPeriodCount: 6,
    interestRateMultiplier: 1,
    installmentCount: 6,
    // installmentCountCF: 6,
    postponeEnabled: true,
    postponeFeeMultiplier: 1,
    postponeFeeMultiplierCF: 0,
    prolongationEnabled: false,
    // prolongationFeeMultiplier: 1,
  },
  M12: {
    // id: Product.M12,
    id: 'M12',
    // text: '12 měsíců',
    text: `12 ${i18n.t('common-months')}`,
    typProduktu: ProductPeriod.M12,
    baloon: false,
    installmentPeriod: InstallmentPeriod.Month,
    // installmentPeriodCount: 12,
    interestRateMultiplier: 1,
    installmentCount: 12,
    // installmentCountCF: 12,
    postponeEnabled: true,
    postponeFeeMultiplier: 1,
    postponeFeeMultiplierCF: 0,
    prolongationEnabled: false,
    // prolongationFeeMultiplier: 1,
  },
};

export function getProductByCode(code: string) {
  return ProductsArr.find((i) => i.code === code);
}
