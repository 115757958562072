import { Box, Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { formatMoney } from '../../../utils/format';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { IsoCurrencyCode } from '../../../types/api';

interface IPaymentsDetailsProps {
  currency?: IsoCurrencyCode;
  financingValue: number;
  feeMonthly: number;
  amountComplete: number;
  prolongationFee?: number;
  postponementFee?: number;
  isIndicativeOffer?: boolean;
}

const PaymentsDetails: FC<IPaymentsDetailsProps> = ({
  amountComplete,
  feeMonthly,
  financingValue,
  currency,
  prolongationFee,
  postponementFee,
  isIndicativeOffer,
}) => {
  const { t } = useTranslation();
  const anyFee = prolongationFee || postponementFee;
  const isExtraSmallScreen = useMediaQuery('(max-width:380px)');

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="center"
        alignItems="center"
        sx={{
          margin: isIndicativeOffer
            ? '5px 0'
            : { sm: '28px 0', xs: '32px 0 16px 0' },
        }}
      >
        <Typography
          fontWeight="bold"
          variant="h6"
          textAlign="center"
          sx={{ fontSize: '1.125rem' }}
        >
          {t('offer-summary')}
        </Typography>
      </Stack>
      <Box
        sx={{
          bgcolor: '#F5F8FB',
          borderRadius: isIndicativeOffer ? 1 : 2,
          display: 'flex',
          justifyContent: { xs: 'space-around', sm: 'center' },
          alignItems: 'center',
          margin: isIndicativeOffer
            ? '5px 0 10px 0'
            : { xs: '0 0 32px 0', sm: '0 0 28px 0' },
          padding: isIndicativeOffer ? '0' : { xs: '20px', sm: '0' },
          width: '100%',
          flexWrap: { xs: 'wrap', sm: 'nowrap' },
        }}
      >
        <Box
          sx={{
            flex: isIndicativeOffer ? 'none' : 0,
            margin: isIndicativeOffer ? 1 : { xs: 0, sm: 2 },
            padding: isIndicativeOffer ? 0.5 : { xs: 0, sm: 2 },
          }}
        >
          <Typography
            variant={isIndicativeOffer ? 'subtitle1' : 'h6'}
            sx={{
              whiteSpace: { xs: 'wrap', sm: 'nowrap' },
              display: 'inline-block',
              width: isIndicativeOffer
                ? '120px'
                : isExtraSmallScreen
                ? '120px'
                : '147px',
              textAlign: 'center',
              marginBottom: isIndicativeOffer ? 0.5 : 1,
              fontWeight: 'medium',
              fontSize: isIndicativeOffer ? '.8rem' : '1.125rem',
            }}
          >
            {t('offer-financing-amount')}
          </Typography>
          <Divider
            sx={{
              display: isIndicativeOffer
                ? 'block'
                : { xs: 'none', sm: 'block' },
            }}
          />
          <Typography
            mt={isIndicativeOffer ? 0.5 : 1}
            variant={isIndicativeOffer ? 'subtitle1' : 'h6'}
            fontWeight="bold"
            textAlign="center"
            sx={{ fontSize: isIndicativeOffer ? '1rem' : '1.125rem' }}
          >
            {formatMoney(financingValue, currency)}
          </Typography>
        </Box>
        <Divider
          sx={{
            display: isIndicativeOffer ? 'none' : { xs: 'block', sm: 'none' },
          }}
          orientation="vertical"
          flexItem
        />
        <Box
          sx={{
            flex: isIndicativeOffer ? 'none' : 0,
            margin: isIndicativeOffer ? 1 : { xs: 0, sm: 2 },
            padding: isIndicativeOffer ? 0.5 : { xs: 0, sm: 2 },
          }}
        >
          {Boolean(anyFee) ? (
            <>
              <Typography
                sx={{
                  fontSize: isIndicativeOffer ? '.8rem' : 'inherit',
                  margin: 0,
                  whiteSpace: { xs: 'wrap', sm: 'nowrap' },
                  fontWeight: 'medium',
                  textAlign: 'center',
                  display: 'inline-block',
                  width: isIndicativeOffer
                    ? '120px'
                    : isExtraSmallScreen
                    ? '120px'
                    : '100%',
                }}
              >
                {t('offer-month-fee')}
              </Typography>
              <Typography
                variant={isIndicativeOffer ? 'subtitle2' : 'h6'}
                fontWeight="bold"
                textAlign="center"
              >
                {formatMoney(feeMonthly, currency)}
              </Typography>

              <Divider
                sx={{
                  my: isIndicativeOffer ? 0.5 : 1,
                }}
              />
              <Typography
                sx={{
                  fontSize: isIndicativeOffer ? '12px' : 'inherit',
                  whiteSpace: { xs: 'wrap', sm: 'nowrap' },
                  fontWeight: 'medium',
                  textAlign: 'center',
                }}
              >
                {prolongationFee
                  ? t('offer-prolongation-fee')
                  : t('offer-postpone-fee')}
              </Typography>
              <Typography
                variant={isIndicativeOffer ? 'subtitle2' : 'h6'}
                textAlign="center"
              >
                {formatMoney(anyFee, currency)}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant={isIndicativeOffer ? 'subtitle1' : 'h6'}
                sx={{
                  whiteSpace: { xs: 'wrap', sm: 'nowrap' },
                  display: 'inline-block',
                  width:
                    isIndicativeOffer || isExtraSmallScreen ? '120px' : '147px',
                  fontWeight: 'medium',
                  marginBottom: isIndicativeOffer ? 0.5 : 1,
                  textAlign: 'center',
                  fontSize: isIndicativeOffer ? '.8rem' : '1.125rem',
                }}
              >
                {t('offer-month-fee')}
              </Typography>
              <Divider
                sx={{
                  display: isIndicativeOffer
                    ? 'block'
                    : {
                        xs: 'none',
                        sm: 'block',
                      },
                }}
              />
              <Typography
                mt={isIndicativeOffer ? 0.5 : 1}
                variant={isIndicativeOffer ? 'subtitle1' : 'h6'}
                fontWeight="bold"
                textAlign="center"
                sx={{ fontSize: isIndicativeOffer ? '1rem' : '1.125rem' }}
              >
                {formatMoney(feeMonthly, currency)}
              </Typography>
            </>
          )}
        </Box>

        <Box
          sx={{
            border: isIndicativeOffer
              ? '2px solid #00DEC4'
              : '3px solid #00DEC4',
            borderRadius: isIndicativeOffer ? 1 : 2,
            p: isIndicativeOffer ? 0.5 : 2,
            m: isIndicativeOffer ? 0 : { xs: '16px 16px 0 16px', sm: 2 },
            mt: isIndicativeOffer ? 1 : { sm: 2, xs: 3 },
            flexBasis: isIndicativeOffer ? 'auto' : { xs: '100%', sm: 'auto' },
          }}
        >
          <Typography
            sx={{
              whiteSpace: { xs: 'wrap', sm: 'nowrap' },
              fontSize: isIndicativeOffer ? '.8rem' : '1rem',
            }}
            mb={isIndicativeOffer ? 0.5 : 1}
            variant={isIndicativeOffer ? 'subtitle1' : 'h6'}
            fontWeight="medium"
            textAlign="center"
          >
            {t('offer-total-amount')}
          </Typography>
          <Divider
            sx={{
              display: isIndicativeOffer
                ? 'block'
                : { xs: 'none', sm: 'block' },
            }}
          />
          <Typography
            mt={isIndicativeOffer ? 0.5 : { xs: 0, sm: 1 }}
            variant={isIndicativeOffer ? 'subtitle1' : 'h6'}
            fontWeight="bold"
            textAlign="center"
            sx={{
              fontSize: isIndicativeOffer
                ? '1.25rem'
                : { xs: '1.5rem', sm: '1rem' },
            }}
          >
            {formatMoney(amountComplete, currency)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PaymentsDetails;
