import { FC, FormEvent, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import Dialog from '../UI/Dialog';
import Button from '../UI/Button';
import TextEdit from '../UI/TextEdit';

import axios from '../../utils/axios';
import { useLoading } from '../../context/loading-context';
import { useErrorHandler } from '../../context/error-handler-context';

import styles from './ConnectionDialog.module.scss';
import { ExternalUserDto } from '../../types/api';
import { COLORS } from '../../constants/colors';

interface IConnectionDialogProps {
  onClose: (value: boolean) => void;
  open: boolean;
  customerId: string;
  selectedPartner: any;
  currentUser: ExternalUserDto;
}

const ConnectionDialog: FC<IConnectionDialogProps> = ({
  currentUser,
  customerId,
  onClose,
  open,
  selectedPartner,
}) => {
  const { t } = useTranslation();

  const company = currentUser.customers?.find((c) => c.id === customerId);

  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const [fullName, setFullName] = useState<string>(currentUser?.name ?? '');
  const [companyName, setCompanyName] = useState<string>(company?.name ?? '');
  const [phone, setPhone] = useState<string>(currentUser?.phone ?? '');
  const [email, setEmail] = useState<string>(currentUser?.email ?? '');

  const handleClose = (success = false) => {
    resetDialog();
    onClose(success);
  };

  const resetDialog = () => {
    setFullName('');
    setCompanyName('');
    setPhone('');
    setEmail('');
  };

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {
      partnerCode: selectedPartner.name,
      customerId,
      fullName,
      companyName,
      phone,
      email,
    };

    setLoading(true);
    axios
      .post(
        `partner-connections/customers/${customerId}/partner-requests`,
        data
      )
      .then(() => {
        setLoading(false);
        handleClose(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <Dialog
      open={open}
      title={t('connections-dialog-title-alt')}
      handleClose={handleClose}
    >
      <div className={styles['dialog-wrapper']}>
        {selectedPartner.url && (
          <DialogContent
            sx={{
              p: '0.5rem 5rem 0.5rem 5rem !important',
              color: COLORS.text,
              fontWeight: 700,
              fontSize: '1.2rem',
              lineHeight: 'initial',
              fontFamily: '"Inter", sans-serif',
            }}
          >
            <DialogContentText>
              <span>
                <Trans
                  i18nKey="connections-dialog-custom-info"
                  components={[selectedPartner.name]}
                ></Trans>
              </span>
            </DialogContentText>
            <DialogActions sx={{ p: '0rem 5rem 2rem 5rem' }}>
              <Button
                className="gradient dialog"
                onClick={() => {
                  window.open(selectedPartner.url, '_blank');
                  onClose(false);
                }}
              >
                <span>
                  <Trans
                    i18nKey="connections-dialog-custom-button"
                    components={[selectedPartner.name]}
                  ></Trans>
                </span>
              </Button>
            </DialogActions>
          </DialogContent>
        )}

        {!selectedPartner.url && (
          <form onSubmit={submitHandler}>
            <DialogContent
              sx={{
                p: '0.5rem 5rem 0.5rem 5rem !important',
                color: COLORS.text,
                fontWeight: 700,
                fontSize: '1.2rem',
                lineHeight: 'initial',
                fontFamily: '"Inter", sans-serif',
              }}
            >
              <DialogContentText>
                <Trans
                  i18nKey="connections-dialog-info-alt"
                  components={[selectedPartner.name]}
                ></Trans>
              </DialogContentText>

              <TextEdit
                autoFocus={true}
                id="fullName"
                label={t('connections-dialog-form-full-name')}
                value={fullName}
                required
                onChange={(value: string) => {
                  setFullName(value);
                }}
              />
              <TextEdit
                id="companyName"
                label={t('connections-dialog-form-company')}
                value={companyName}
                required
                onChange={(value: string) => {
                  setCompanyName(value);
                }}
              />
              <TextEdit
                id="phone"
                pattern="^\+(?:[0-9] ?){6,14}[0-9]$"
                placeholder="+420 xxx xxx xxx"
                label={t('connections-dialog-form-phone')}
                value={phone}
                required
                onChange={(value: string) => {
                  setPhone(value);
                }}
              />
              <TextEdit
                id="email"
                type="email"
                label={t('connections-dialog-form-email')}
                value={email}
                required
                onChange={(value: string) => {
                  setEmail(value);
                }}
              />
            </DialogContent>
            <DialogActions sx={{ p: '0rem 5rem 2rem 5rem' }}>
              <Button type="submit" className="gradient dialog">
                <span>{t('connections-dialog-form-send')}</span>
              </Button>
            </DialogActions>
          </form>
        )}
      </div>
    </Dialog>
  );
};

export default ConnectionDialog;
