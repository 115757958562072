import {
  useState,
  useRef,
  useEffect,
  FC,
  ChangeEvent,
  KeyboardEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Stack,
  InputBase,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { default as MuiSlider } from '@mui/material/Slider';

import { useErrorHandler } from '../../../context/error-handler-context';
import { formatMoney } from '../../../utils/format';

import CustomThumb from '../../UI/CustomThumb';
import { COLORS } from '../../../constants/colors';
import { IsoCurrencyCode } from '../../../types/api';

interface ISliderProps {
  value: number;
  minValue: number;
  maxValue: number;
  updateValue: (value: number) => void;
  currency?: IsoCurrencyCode;
  isIndicative?: boolean;
}

const Slider: FC<ISliderProps> = ({
  isIndicative,
  maxValue,
  minValue,
  updateValue,
  value,
  currency,
}) => {
  const inputRef = useRef<any>();

  const { t } = useTranslation();
  const { setError } = useErrorHandler();

  const [valueTxt, setValueTxt] = useState('');

  const beforeUpdateValue = (valueTxt: string) => {
    let newValue = parseInt(valueTxt.replace(/\D/g, ''));
    newValue = Math.ceil(newValue / 100) * 100;

    if (newValue < minValue || newValue > maxValue) {
      setValueTxt(formatMoney(value, currency));
      setError(t('offer-slider-error'));
      return;
    }

    if (newValue !== value) {
      updateValue(newValue);
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    beforeUpdateValue(event.target.value);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      beforeUpdateValue(event.currentTarget.value);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValueTxt(event.target.value);
  };

  const handleOnChangeSlider = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    if (typeof value === 'number') {
      updateValue(value);
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmallScreen = useMediaQuery('(max-width:450px)');

  const sliderSX = {
    color: '#FF4F90',
    height: isIndicative ? 7 : 10,
    marginBottom: `${isIndicative ? '10px' : '28'}px`,
    marginTop: isIndicative ? '10px' : '15px',
    padding: `${isIndicative ? '10px 0' : '20px 0'} !important`,
    '& .MuiSlider-thumb': {
      height: isIndicative ? 20 : 29,
      width: isIndicative ? 45 : 51,
      borderRadius: 5,
      pointerEvents: 'none',

      '& .airbnb-bar': {
        height: 9,
        width: '2px',
        backgroundColor: '#FFFFFF',
        marginLeft: '2px',
        marginRight: '2px',
      },
    },
    '& .MuiSlider-rail': {
      color: '#C4C4C4',
    },
    '& .MuiSlider-markLabel': {
      color: COLORS.text,
      fontFamily: 'Inter',
      fontWeight: 800,
      fontSize: `${isIndicative ? '.8' : '1.25'}rem`,
      marginTop: isIndicative ? 1 : 2,
      '&[data-index="0"]': {
        transform: 'none',
      },
      '&[data-index="1"]': {
        transform: 'translateX(-100%)',
      },
    },
    '& .MuiSlider-mark': {
      width: 0,
      height: 0,
    },
  };

  const sliderChangeStep = currency === IsoCurrencyCode.CZK ? 5000 : 500;
  const buttonStepChange = currency === IsoCurrencyCode.CZK ? 1000 : 100;

  useEffect(() => {
    setValueTxt(formatMoney(value, currency));
  }, [value, currency]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          margin: isIndicative
            ? 0
            : { xs: isExtraSmallScreen ? '0 -22px' : '0 -52px', sm: 0 },
          '& svg': {
            width: isIndicative ? '45px' : '73px',
            height: isIndicative ? '37px' : '56px',
            fill: '#ff4f90',
            cursor: 'pointer',
            border: '1px solid #c6cbd0',
            borderRadius: isIndicative ? '9px' : { sm: '9px', xs: '27.5px' },
          },
          '@media (max-width:650px)': {
            ...(isIndicative
              ? {}
              : {
                  '& svg:first-of-type': {
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                    borderLeft: 'none',
                  },
                  '& svg:last-of-type': {
                    borderTopRightRadius: '0',
                    borderBottomRightRadius: '0',
                    borderRight: 'none',
                  },
                }),
          },
        }}
      >
        <svg
          viewBox="0 0 1024 1024"
          onClick={() => updateValue(value - buttonStepChange)}
        >
          <path d="M768 469.333h-512c-47.104 0-85.333 38.229-85.333 85.333s38.229 85.333 85.333 85.333h512c47.104 0 85.333-38.229 85.333-85.333s-38.229-85.333-85.333-85.333z" />
        </svg>

        <InputBase
          value={valueTxt}
          spellCheck={false}
          inputRef={inputRef}
          onBlur={handleOnBlur}
          onKeyDown={handleOnKeyDown}
          onChange={handleOnChange}
          onDoubleClick={() => {
            inputRef.current.selectionStart = 0;
            inputRef.current.selectionEnd = inputRef.current.value.length;
          }}
          inputProps={{
            style: {
              textAlign: 'center',
              fontFamily: 'Inter',
              fontWeight: 800,
              fontSize: `${
                isIndicative && isExtraSmallScreen
                  ? '1.1'
                  : isIndicative
                  ? '1.3'
                  : isSmallScreen
                  ? '1.5'
                  : '2.5'
              }rem`,
              color: '#FF4F90',
              height: isIndicative ? '37px' : isSmallScreen ? '47px' : '56px',
              width:
                isExtraSmallScreen && isIndicative
                  ? '160px'
                  : isSmallScreen
                  ? '220px'
                  : '300px',
            },
          }}
          sx={{
            border: '1px solid #c6cbd0',
            borderRadius: '9px',
            height: isIndicative ? '37px' : isSmallScreen ? '47px' : '56px',
          }}
        />

        <svg
          viewBox="0 0 1024 1024"
          onClick={() => updateValue(value + buttonStepChange)}
        >
          <path d="M768 426.667h-170.667v-170.667c0-47.104-38.229-85.333-85.333-85.333s-85.333 38.229-85.333 85.333l3.029 170.667h-173.696c-47.104 0-85.333 38.229-85.333 85.333s38.229 85.333 85.333 85.333l173.696-3.029-3.029 173.696c0 47.104 38.229 85.333 85.333 85.333s85.333-38.229 85.333-85.333v-173.696l170.667 3.029c47.104 0 85.333-38.229 85.333-85.333s-38.229-85.333-85.333-85.333z" />
        </svg>
      </Stack>

      {minValue !== maxValue && (
        <Box
          sx={{ display: isIndicative ? 'block' : { xs: 'none', sm: 'block' } }}
        >
          <MuiSlider
            valueLabelDisplay="off"
            onChange={handleOnChangeSlider}
            min={minValue}
            max={maxValue}
            step={sliderChangeStep}
            value={value}
            sx={sliderSX}
            marks={
              isIndicative
                ? false
                : [
                    {
                      value: minValue,
                      label: (
                        <Typography variant="h4" fontWeight={800}>
                          {formatMoney(minValue, currency)}
                        </Typography>
                      ),
                    },
                    {
                      value: maxValue,
                      label: (
                        <Typography variant="h4" fontWeight={800}>
                          {formatMoney(maxValue, currency)}
                        </Typography>
                      ),
                    },
                  ]
            }
            components={{ Thumb: CustomThumb }}
          />
        </Box>
      )}
    </>
  );
};

export default Slider;
