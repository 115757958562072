import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';

import { styled } from '@mui/material/styles';
import styles from './Filter.module.scss';
import { FC } from 'react';
import {
  ExternalUserDto,
  ExternalUserDtoConnectedPartnerDto,
  ExternalUserDtoCustomerDto,
} from '../../types/api';

interface IPartnerImageProps {
  name: string;
}

const SelectStyled = styled((props: SelectProps<string>) => (
  <Select {...props} />
))(() => ({
  '.MuiSelect-select': {
    padding: '0.4rem!important',
    color: '#838383',
    fontFamily: '"Nunito", sans-serif',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '0.85rem',
    border: '1px solid #939393',
    height: 'initial',
    minHeight: 'inherit',
    lineHeight: 'initial',
    marginLeft: '0.8rem',
  },
  '.MuiSvgIcon-root': {
    display: 'none',
  },
  fieldset: {
    border: 0,
  },
}));

const PartnerImage: FC<IPartnerImageProps> = ({ name }) => {
  try {
    const image = require(`../../assets/partners/${name}.png`);
    if (!image) return null;

    return <img src={image} title={name} alt={name} />;
  } catch (error) {
    return null;
  }
};

interface IFilterProps {
  customerId: string;
  currentUser: ExternalUserDto;
  partner: ExternalUserDtoConnectedPartnerDto;
  changePartner?: (item: ExternalUserDtoConnectedPartnerDto) => void;
}

const Filter: FC<IFilterProps> = ({
  changePartner,
  currentUser,
  partner,
  customerId,
}) => {
  const { t } = useTranslation();
  const partners = currentUser?.customers?.find(
    (c: ExternalUserDtoCustomerDto) => c?.id === customerId
  )?.partners;

  const handlePartnerChange = (event: SelectChangeEvent<string>): void => {
    const value = event.target.value;
    const selectedPartner = partners?.find(
      (p: ExternalUserDtoConnectedPartnerDto) => p.code === value
    );

    if (changePartner && selectedPartner) {
      changePartner(selectedPartner);
    }
  };

  return (
    <div className={styles['filter-wrapper']}>
      <div className={styles.partners}>
        <label>{t('overview-filter-partner')}</label>
        <SelectStyled value={partner.code} onChange={handlePartnerChange} variant='outlined'>
          {partners?.map(
            (p: ExternalUserDtoConnectedPartnerDto, index: number) => {
              return (
                <MenuItem key={index} value={p.code}>
                  {p.name}
                </MenuItem>
              );
            }
          )}
        </SelectStyled>
      </div>
      <div className={styles.client}>
        {partner && <PartnerImage name={partner.code ?? ''} />}
      </div>
    </div>
  );
};

export default Filter;
