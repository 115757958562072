import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import { formatDate, formatMoney } from '../../utils/format';
import { IsoCurrencyCode, LoanDtoPaymentInstructionDto } from '../../types/api';

interface IPaymentInfoProps {
  paymentInstruction: LoanDtoPaymentInstructionDto;
  currency: IsoCurrencyCode;
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  [theme.breakpoints.down(700)]: {
    fontSize: '.875rem',
  },
}));

const PaymentInfo: FC<IPaymentInfoProps> = ({
  paymentInstruction,
  currency,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="h5" sx={{ color: '#838383', marginBottom: '.5rem' }}>
        {t('payment-instruction-title')}
      </Typography>

      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table aria-label="payment instruction table">
          <TableBody>
            <TableRow>
              <TableCell sx={{ padding: '0 .5rem 0 0', borderBottom: 'none' }}>
                <StyledTypography variant="h6" sx={{ fontWeight: 400 }}>
                  {t('payment-instruction-account-number')}:
                </StyledTypography>
              </TableCell>
              <TableCell sx={{ padding: 0, borderBottom: 'none' }}>
                <StyledTypography variant="h6" sx={{ fontWeight: 600 }}>
                  {paymentInstruction.currency === IsoCurrencyCode.CZK
                    ? paymentInstruction.bban
                    : paymentInstruction.iban}
                </StyledTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding: '0 .5rem 0 0', borderBottom: 'none' }}>
                <StyledTypography variant="h6" sx={{ fontWeight: 400 }}>
                  {t('payment-instruction-reference')}:
                </StyledTypography>
              </TableCell>
              <TableCell sx={{ padding: 0, borderBottom: 'none' }}>
                <StyledTypography variant="h6" sx={{ fontWeight: 600 }}>
                  {paymentInstruction.reference}
                </StyledTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding: '0 .5rem 0 0', borderBottom: 'none' }}>
                <StyledTypography variant="h6" sx={{ fontWeight: 400 }}>
                  {t('payment-instruction-amount')}:
                </StyledTypography>
              </TableCell>
              <TableCell sx={{ padding: 0, borderBottom: 'none' }}>
                <StyledTypography variant="h6" sx={{ fontWeight: 600 }}>
                  {formatMoney(paymentInstruction.amount, currency)}
                </StyledTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ padding: '0 .5rem 0 0', borderBottom: 'none' }}>
                <StyledTypography variant="h6" sx={{ fontWeight: 400 }}>
                  {t('payment-instruction-due-date')}:
                </StyledTypography>
              </TableCell>
              <TableCell sx={{ padding: 0, borderBottom: 'none' }}>
                <StyledTypography variant="h6" sx={{ fontWeight: 600 }}>
                  {formatDate(paymentInstruction.dueDate)}
                </StyledTypography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PaymentInfo;
