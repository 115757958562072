import {
  Grid,
  TextField,
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useState, FC, useEffect, ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import axios from '../../utils/axios';
import moment from 'moment';
import { COLORS } from '../../constants/colors';
import { addSnackbar } from '../../store/slices/snackbarsSlice';
import {
  ApplicationSubmitRep2Dto,
  ApplicationSubmitRep2DtoCustom,
  CountryDto,
  CustomerDto,
  ExternalUserDto,
  RepresentativeDto,
  RepresentativeDto1,
} from '../../types/api';
import { useAppDispatch } from '../../selectors/selectors';
import { useScreenSize } from '../../hooks';
import Button from '../UI/Button';

interface INewExecutiveFormProps {
  applicationId: string;
  customerId: string;
  currentUser: ExternalUserDto;
  submitHandler: (
    data: ApplicationSubmitRep2Dto | ApplicationSubmitRep2DtoCustom,
    representativeId: string
  ) => void;
}

const NewRep2Form: FC<INewExecutiveFormProps> = ({
  applicationId,
  customerId,
  currentUser,
  submitHandler,
}) => {
  const [customRep2Errors, setCustomRep2Errors] = useState<
    Partial<ApplicationSubmitRep2DtoCustom>
  >({});
  const [countries, setCountries] = useState<Array<CountryDto>>([]);
  const [countryInputValue, setCountryInputValue] = useState<string>('');
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [stopRequests, setStopRequests] = useState<boolean>(false);
  const { isMediumScreen } = useScreenSize();
  const [rep1, setRep1] = useState<RepresentativeDto | null>(null);

  const [customRep2, setCustomRep2] = useState<ApplicationSubmitRep2DtoCustom>({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    address: {
      line1: '',
      city: '',
      zip: '',
      country: '',
    },
    phone: '',
  });

  const [currentRepresentativeId, setCurrentRepresentativeId] = useState('');
  const [companyRepresentatives, setCompanyRepresentatives] = useState<
    Array<any>
  >([]);

  const currentRepresentativeEmail = companyRepresentatives?.find(
    (rep) => rep.id === currentRepresentativeId
  )?.email;

  const dispatch = useAppDispatch();

  const getCountries = async () => {
    try {
      const res = await axios.get('parties/countries');
      setLoading(false);
      setCountries(res.data);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = (currentRepresentativeId: string) => {
    const errors = {
      address: {
        city: '',
        line1: '',
        zip: '',
        country: '',
      },
      firstName: '',
      lastName: '',
      dob: '',
      email: '',
    };
    let formIsValid = true;

    if (currentRepresentativeId === 'other') {
      if (!customRep2.firstName) {
        errors.firstName = t('validation.field-required');
        formIsValid = false;
      }
      if (!customRep2.lastName) {
        errors.lastName = t('validation.field-required');
        formIsValid = false;
      }
      if (!customRep2.dob) {
        errors.dob = t('validation.field-required');
        formIsValid = false;
      }
      if (!customRep2.address.city) {
        errors.address.city = t('validation.field-required');
        formIsValid = false;
      }
      if (!customRep2.address.zip) {
        errors.address.zip = t('validation.field-required');
        formIsValid = false;
      }

      if (!customRep2.address.line1) {
        errors.address.line1 = t('validation.field-required');
        formIsValid = false;
      }
      if (!customRep2.address.country) {
        errors.address.country = t('validation.field-required');
        formIsValid = false;
      }
    }
    const emailPattern = /\S+@\S+\.\S+/;

    if (!customRep2.email) {
      errors.email = t('validation.field-required');
      formIsValid = false;
    } else if (!emailPattern.test(customRep2.email)) {
      errors.email = t('validation.incorrect-email');
      formIsValid = false;
    } else if (customRep2.email === rep1?.email) {
      errors.email = t('validation.email-used');
      formIsValid = false;
    } else if (
      companyRepresentatives.some((rep) => rep.email === customRep2.email) &&
      !currentRepresentativeEmail
    ) {
      errors.email = t('validation.email-used');
      formIsValid = false;
    }

    setCustomRep2Errors(errors);
    return formIsValid;
  };

  const updateCustomRep2Data = (
    fields: Partial<ApplicationSubmitRep2DtoCustom>
  ) => {
    setCustomRep2((prev) => {
      return { ...prev, ...fields };
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name.startsWith('address.')) {
      const addressField = name.replace('address.', '');
      updateCustomRep2Data({
        ...customRep2,
        address: {
          ...customRep2.address,
          [addressField]: value,
        },
      });
    } else {
      updateCustomRep2Data({
        ...customRep2,
        [name]: value,
      });
    }
  };

  const getCurrentRepresentatives = async () => {
    try {
      setLoading(true);
      if (!customerId) {
        return;
      }
      const currentCustomerData = await axios.get<CustomerDto>(
        `customers/${customerId}`
      );
      const companyRepresentatives = await axios.get<
        Array<RepresentativeDto | RepresentativeDto1>
      >(
        // `/customers/${customerId}/representatives`
        `/applications/${applicationId}/customer-representatives`
      );

      // Check if both currentCustomerData and companyRepresentatives are available
      if (
        currentCustomerData &&
        companyRepresentatives &&
        companyRepresentatives.data.length > 0
      ) {
        // Stop further requests by setting stopRequests to true
        setStopRequests(true);
        setLoading(false);

        const rep1 = companyRepresentatives?.data?.find(
          (comp) => comp.id === currentUser.partyId
        );

        if (rep1) {
          setRep1(rep1 as RepresentativeDto);
        }

        const filteredCompanyRepresentatives =
          companyRepresentatives?.data?.filter(
            (comp) => comp.id !== currentUser.partyId
          );

        setCurrentRepresentativeId(
          filteredCompanyRepresentatives?.[0]?.id ?? 'other'
        );

        setCompanyRepresentatives(
          filteredCompanyRepresentatives as Array<RepresentativeDto1>
        );
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        addSnackbar({
          variant: 'error',
          message: t('registration.errors.general-registration-error'),
        })
      );
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentRepresentativeId((event.target as HTMLInputElement).value);
    setCustomRep2Errors({});
  };

  const createDataToSubmit = (
    currentRepresentativeId: string
  ): ApplicationSubmitRep2Dto | ApplicationSubmitRep2DtoCustom => {
    if (currentRepresentativeId === 'other') {
      return {
        ...customRep2,
      } as ApplicationSubmitRep2DtoCustom;
    } else {
      return {
        id: currentRepresentativeId,
        email: currentRepresentativeEmail || customRep2.email,
      } as ApplicationSubmitRep2Dto;
    }
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    const isValid = isFormValid(currentRepresentativeId);

    if (isValid) {
      const dataToSubmit = createDataToSubmit(currentRepresentativeId);
      submitHandler(dataToSubmit, currentRepresentativeId);
    }
  };

  useEffect(() => {
    if (currentUser && !stopRequests) {
      const makeRequest = async () => {
        try {
          await getCurrentRepresentatives();
        } catch (error) {
          dispatch(
            addSnackbar({
              variant: 'error',
              message: t('registration.errors.general-registration-error'),
            })
          );
        }
      };

      const id = setInterval(makeRequest, 5000);

      setTimeout(() => {
        clearInterval(id);
        setStopRequests(true);
        setLoading(false);
      }, 120000); // 2 minutes in milliseconds

      makeRequest();

      return () => {
        clearInterval(id);
      };
    }
  }, [currentUser, stopRequests]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    setCustomRep2((prevState) => {
      return {
        ...prevState,
        email: currentRepresentativeEmail ?? '',
      };
    });
  }, [currentRepresentativeEmail]);

  return (
    <form onSubmit={onSubmit}>
      {!loading ? (
        <>
          <FormControl sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '15rem',
                overflowY:
                  companyRepresentatives.length >= 2 ? 'scroll' : 'none',
                marginBottom: 2.5,
              }}
            >
              <RadioGroup
                value={currentRepresentativeId}
                onChange={handleChange}
                sx={{ width: '100%' }}
              >
                <FormGroup>
                  {companyRepresentatives.map((rep) => {
                    const {
                      dob = '',
                      address: {
                        line1 = '',
                        city = '',
                        country = '',
                        zip = '',
                      },
                    } = rep ?? {};

                    return (
                      <FormControlLabel
                        key={rep.id}
                        sx={{
                          border:
                            rep.id === currentRepresentativeId
                              ? `2px solid ${COLORS.primary}`
                              : 'none',
                          borderRadius: '10px',
                          padding: '1rem',
                          marginLeft: 0,
                          width: '100%',
                        }}
                        value={rep.id}
                        control={<Radio />}
                        componentsProps={{
                          typography: { width: '100%' },
                        }}
                        label={
                          <>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              sx={{ width: '100%' }}
                            >
                              <Box flex="1">
                                <Typography
                                  variant="body1"
                                  sx={{ margin: '0 !important' }}
                                >{`${rep.firstName} ${rep.lastName}`}</Typography>
                              </Box>
                              {isMediumScreen && (
                                <Box flex="1" minWidth="10rem">
                                  <Typography
                                    variant="body1"
                                    sx={{ margin: '0 !important' }}
                                  >
                                    {t('business-name')}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <Divider sx={{ mb: 1, width: '90%' }} />
                            <Box display="flex" justifyContent="space-between">
                              <Box flex="1">
                                <Typography
                                  variant="body1"
                                  sx={{ margin: '0 !important' }}
                                >
                                  {`${line1} ${city} ${country} ${zip}`}
                                </Typography>
                              </Box>
                              {isMediumScreen && (
                                <Box flex="1" minWidth="10rem">
                                  <Typography
                                    variant="body1"
                                    sx={{ margin: '0 !important' }}
                                  >
                                    {t('business-address')}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <Divider sx={{ mb: 1, width: '90%' }} />

                            <Box display="flex" justifyContent="space-between">
                              <Box flex="1">
                                <Typography
                                  variant="body1"
                                  sx={{ margin: '0 !important' }}
                                >
                                  {dob
                                    ? moment(dob).format('DD.MM.YYYY')
                                    : t('common-not-abailable')}
                                </Typography>
                              </Box>

                              {isMediumScreen && (
                                <Box flex="1" minWidth="10rem">
                                  <Typography
                                    variant="body1"
                                    sx={{ margin: '0 !important' }}
                                  >
                                    {t('common-birthday')}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </>
                        }
                      />
                    );
                  })}

                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label={t('registration.registration-add-new-executive')}
                    sx={{
                      padding: '1rem 1rem 0 1rem',
                      marginLeft: 0,
                      width: '100%',
                    }}
                  />
                </FormGroup>
              </RadioGroup>
            </Box>
          </FormControl>

          <Grid container spacing={2}>
            {currentRepresentativeId === 'other' && (
              <>
                <Grid item md={6} xs={12}>
                  <TextField
                    size="small"
                    label={t('registration-form-firstname')}
                    fullWidth
                    name="firstName"
                    value={customRep2.firstName}
                    onChange={handleInputChange}
                    error={!!customRep2Errors.firstName}
                    helperText={customRep2Errors.firstName}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    size="small"
                    label={t('registration-form-lastname')}
                    fullWidth
                    name="lastName"
                    value={customRep2.lastName}
                    onChange={handleInputChange}
                    error={!!customRep2Errors.lastName}
                    helperText={customRep2Errors.lastName}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    disablePortal
                    options={countries}
                    getOptionLabel={(option) => {
                      return option?.name ?? option;
                    }}
                    size="small"
                    isOptionEqualToValue={(option, value) => {
                      return option.code === value;
                    }}
                    value={customRep2.address.country as any}
                    onChange={(event, newValue) => {
                      updateCustomRep2Data({
                        ...customRep2,
                        address: {
                          ...customRep2.address,
                          country: newValue?.code,
                        },
                      });
                    }}
                    inputValue={countryInputValue}
                    onInputChange={(event, newInputValue) =>
                      setCountryInputValue(newInputValue)
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option?.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option?.code.toLowerCase()}.png 2x`}
                          alt="Country flag"
                          style={{ width: '30px', height: '20px' }}
                        />
                        {option.name} ({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t(
                          'registration.registration-add-new-country-label'
                        )}
                        error={!!customRep2Errors.address?.country}
                        helperText={customRep2Errors.address?.country}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    size="small"
                    label={t('registration-form-city')}
                    fullWidth
                    name="address.city"
                    value={customRep2.address.city}
                    onChange={handleInputChange}
                    error={!!customRep2Errors.address?.city}
                    helperText={customRep2Errors.address?.city}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    size="small"
                    label={t('registration-form-street')}
                    fullWidth
                    name="address.line1"
                    value={customRep2.address.line1}
                    onChange={handleInputChange}
                    error={!!customRep2Errors.address?.line1}
                    helperText={customRep2Errors.address?.line1}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    size="small"
                    label={t('registration-form-post')}
                    fullWidth
                    name="address.zip"
                    onChange={handleInputChange}
                    error={!!customRep2Errors.address?.zip}
                    helperText={customRep2Errors.address?.zip}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    size="small"
                    type="date"
                    label={t('registration-form-birthday')}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="dob"
                    onChange={handleInputChange}
                    error={!!customRep2Errors.dob}
                    helperText={customRep2Errors.dob}
                  />
                </Grid>
              </>
            )}
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                value={customRep2.email}
                onChange={handleInputChange}
                size="small"
                name="email"
                label={t('application-rep1-email')}
                fullWidth
                error={!!customRep2Errors.email}
                helperText={customRep2Errors.email}
                disabled={
                  Boolean(currentRepresentativeEmail) &&
                  currentRepresentativeId !== 'other'
                }
              />
            </Grid>
          </Grid>
        </>
      ) : !companyRepresentatives.length && loading ? (
        t('registration.fetching-from-register')
      ) : (
        t('registration.errors.ico-not-found-error')
      )}

      <Box sx={{ margin: '2rem 0' }}>
        <Button type="submit" className="application" id="app_rep2_confirmed">
          {t('application-button-continue')}
        </Button>
      </Box>
    </form>
  );
};

export default NewRep2Form;
