import { FC, useState, ChangeEvent, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

import { RegistrationSteps } from '../../../../constants/registrationSteps';
import { FormWrapper } from '../FormWrapper';
import axios from '../../../../utils/axios';
import Loading from '../../../UI/Loading';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../selectors/selectors';
import { useLoading } from '../../../../context/loading-context';
import { TUserRegisterData } from '../registrationSchema';
import { addSnackbar } from '../../../../store/slices/snackbarsSlice';

interface INewExecutiveProps {
  title: string;
  moveToStep: (step: RegistrationSteps, saveHistory?: boolean) => void;
  registrationData: TUserRegisterData;
  updateRegistrationData: (fields: Partial<TUserRegisterData>) => void;
}

export const NewExecutive: FC<INewExecutiveProps> = ({
  title,
  moveToStep,
  registrationData,
  updateRegistrationData,
}) => {
  const { t } = useTranslation();
  const { loading, setLoading } = useLoading();
  const [countries, setCountries] = useState<
    Array<TUserRegisterData['newExecutive']['address']['country']>
  >([]);
  const [countryInputValue, setCountryInputValue] = useState<string>('');
  const [registrationDataErrors, setRegistrationDataErrors] = useState<
    Partial<TUserRegisterData['newExecutive']>
  >({});
  const { newExecutive } = registrationData;
  const { currentUser } = useAppSelector((state) => state.currentUser);

  const dispatch = useAppDispatch();

  const isFormValid = (): boolean => {
    const errors: TUserRegisterData['newExecutive'] = {
      address: {
        city: '',
        line1: '',
        zip: '',
        country: { code: '', name: '', phone: '' },
      },
      firstName: '',
      lastName: '',
      dob: '',
    };
    let formIsValid = true;
    if (!newExecutive.firstName) {
      errors.firstName = t('registration.validation.firstname-required');
      formIsValid = false;
    }
    if (!newExecutive.lastName) {
      errors.lastName = t('registration.validation.lastname-required');
      formIsValid = false;
    }
    if (!newExecutive.dob) {
      errors.dob = t('registration.validation.birthday-required');
      formIsValid = false;
    }
    if (!newExecutive.address.city) {
      errors.address.city = t('registration.validation.city-required');
      formIsValid = false;
    }

    if (!newExecutive.address.zip) {
      errors.address.zip = t('registration.validation.zip-required');
      formIsValid = false;
    }
    if (!newExecutive.address.line1) {
      errors.address.line1 = t('registration.validation.street-required');
      formIsValid = false;
    }
    if (!newExecutive.address.country?.code) {
      errors.address.country.code = t(
        'registration.validation.country-required'
      );
    }

    setRegistrationDataErrors(errors);

    return formIsValid;
  };

  const getCountries = async () => {
    try {
      const res = await axios.get('parties/countries');
      setLoading(false);
      setCountries(res.data);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name.startsWith('address.')) {
      const addressField = name.replace('address.', '');
      updateRegistrationData({
        newExecutive: {
          ...newExecutive,
          address: {
            ...newExecutive.address,
            [addressField]: value,
          },
        },
      });
    } else {
      updateRegistrationData({
        newExecutive: {
          ...newExecutive,
          [name]: value,
        },
      });
    }
  };

  const handleSubmitData = async () => {
    if (isFormValid()) {
      const dataToSubmit = {
        firstName: newExecutive.firstName,
        lastName: newExecutive.lastName,
        dob: newExecutive.dob,
        address: {
          ...newExecutive.address,
          country: newExecutive.address?.country?.code.toLowerCase() ?? '',
        },
        version: 0,
      };
      try {
        setLoading(true);
        await axios.patch(
          `parties/${currentUser?.partyId}/personal-profile`,
          dataToSubmit
        );
        setLoading(false);
        moveToStep(RegistrationSteps.Phone, false);
      } catch (error) {
        setLoading(false);
        dispatch(
          addSnackbar({
            variant: 'error',
            message: t('registration.errors.general-registration-error'),
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <FormWrapper title={title} currentStep={5} onSubmit={handleSubmitData}>
      <Loading isLoading={loading} />
      <Grid container spacing={2} sx={{ width: { xs: '100%', sm: '60%' } }}>
        <Grid md={6} xs={12}>
          <TextField
            size="small"
            label={t('registration-form-firstname')}
            fullWidth
            name="firstName"
            value={newExecutive.firstName}
            onChange={handleInputChange}
            error={!!registrationDataErrors.firstName}
            helperText={registrationDataErrors.firstName}
          />
        </Grid>
        <Grid md={6} xs={12}>
          <TextField
            size="small"
            label={t('registration-form-lastname')}
            fullWidth
            name="lastName"
            value={newExecutive.lastName}
            onChange={handleInputChange}
            error={!!registrationDataErrors.lastName}
            helperText={registrationDataErrors.lastName}
          />
        </Grid>
        <Grid md={8} xs={12}>
          <Autocomplete
            disablePortal
            options={countries}
            getOptionLabel={(option) => option?.name ?? ''}
            size="small"
            isOptionEqualToValue={(option, value) => {
              return option?.name === value?.name;
            }}
            value={newExecutive.address.country}
            onChange={(event, newValue) => {
              updateRegistrationData({
                newExecutive: {
                  ...newExecutive,
                  address: {
                    ...newExecutive.address,
                    country: newValue!, // Update the country with the selected option's name
                  },
                },
              });
            }}
            inputValue={countryInputValue}
            onInputChange={(event, newInputValue) => {
              setCountryInputValue(newInputValue);
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option?.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option?.code.toLowerCase()}.png 2x`}
                  alt="Country flag"
                  style={{ width: '30px', height: '20px' }}
                />
                {option?.name ?? ''} ({option?.code ?? ''})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('registration.registration-add-new-country-label')}
                inputProps={{
                  ...params.inputProps,
                }}
                error={!!registrationDataErrors.address?.country.code}
                helperText={registrationDataErrors.address?.country.code}
              />
            )}
          />
        </Grid>
        <Grid md={4} xs={12}>
          <TextField
            size="small"
            label={t('registration-form-city')}
            fullWidth
            name="address.city"
            value={newExecutive.address.city}
            onChange={handleInputChange}
            error={!!registrationDataErrors.address?.city}
            helperText={registrationDataErrors.address?.city}
          />
        </Grid>
        <Grid md={12} xs={12}>
          <TextField
            size="small"
            label={t('registration-form-street')}
            fullWidth
            name="address.line1"
            value={newExecutive.address.line1}
            onChange={handleInputChange}
            error={!!registrationDataErrors.address?.line1}
            helperText={registrationDataErrors.address?.line1}
          />
        </Grid>

        <Grid md={6} xs={12}>
          <TextField
            size="small"
            label={t('registration-form-post')}
            fullWidth
            name="address.zip"
            value={newExecutive.address.zip}
            onChange={handleInputChange}
            error={!!registrationDataErrors.address?.zip}
            helperText={registrationDataErrors.address?.zip}
          />
        </Grid>
        <Grid md={6} xs={12}>
          <TextField
            size="small"
            type="date"
            label={t('registration-form-birthday')}
            InputLabelProps={{ shrink: true }}
            fullWidth
            name="dob"
            value={newExecutive.dob}
            onChange={handleInputChange}
            error={!!registrationDataErrors.dob}
            helperText={registrationDataErrors.dob}
          />
        </Grid>
      </Grid>
    </FormWrapper>
  );
};
