import { useState, FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoading } from '../../context/loading-context';
import { useErrorHandler } from '../../context/error-handler-context';
import axios from '../../utils/axios';

import { formatDate, formatMoney } from '../../utils/format';
import { b64toBlob } from '../../utils/file';
import styles from './PaymentTable.module.scss';
import PdfDialog from '../Dialogs/PdfDialog';
import pdf from '../../assets/pdf.svg';
import { IsoCurrencyCode, LoanDto } from '../../types/api';

interface IPaymentTableProps {
  loan: LoanDto;
}

const PaymentTable: FC<IPaymentTableProps> = ({ loan }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [pdfSrc, setPdfSrc] = useState('');
  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();
  const dueDate = loan?.instalments?.[0].dueDate;

  const months = loan.months ?? 0; //To be sure we don't get ndefined or null
  const monthsTxt =
    months === 1
      ? t('common-month')
      : months >= 5
      ? t('common-months')
      : t('common-months-alt');

  const handleDownload = (e: MouseEvent<HTMLAnchorElement>, url?: string) => {
    e.preventDefault();

    setLoading(true);
    axios
      .post('outputs/documents/authorize', { url })
      .then((res) => {
        console.log(res.data);

        setLoading(false);
        if (res.data && res.data.url) {
          window.open(res.data.url, '_blank');
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const openTerms = (
    e: MouseEvent<HTMLAnchorElement>,
    contractDocId?: string
  ) => {
    e.preventDefault();

    setLoading(true);
    axios
      .get(`documents/${contractDocId}`)
      .then((res) => {
        setLoading(false);
        const contentType = 'application/pdf';
        const blob = b64toBlob(res.data.fileBase64, contentType);
        const blobUrl = URL.createObjectURL(blob);
        setPdfSrc(blobUrl);
        setOpen(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const openVop = (e: MouseEvent<HTMLAnchorElement>, termsId?: string) => {
    e.preventDefault();

    setLoading(true);
    axios
      .get(`terms/${termsId}`)
      .then((res) => {
        setLoading(false);
        const contentType = 'application/pdf';
        const blob = b64toBlob(res.data, contentType);
        const blobUrl = URL.createObjectURL(blob);
        setPdfSrc(blobUrl);
        setOpen(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <div className={styles['payment-table']}>
      <div className={styles['payment-box']}>
        <span className={styles.title}>{t('installment-how-much')}</span>
        <span className={styles.value}>
          {formatMoney(loan.principal, loan.currency as IsoCurrencyCode)}
        </span>
      </div>

      <div className={styles['payment-box']}>
        <span className={styles.title}>{t('installment-how-long')}</span>
        <span className={styles.value}>{`${months} ${monthsTxt}`}</span>
      </div>

      <div className={styles['payment-box']}>
        <span className={styles.title}>
          {t('installment-first-installment')}
        </span>
        <span className={styles.value}>{formatDate(dueDate)}</span>
      </div>

      <div className={styles['payment-box']}>
        <span className={styles.title}>{t('installment-postponement')}</span>
        <span className={styles.value}>
          {loan.postponedPeriods! > 0 || loan.prolonged
            ? t('common-yes')
            : t('common-no')}
        </span>
      </div>

      {loan.contractUrl && (
        <div className={styles['payment-box']}>
          <span className={styles.title}>{t('installment-contract')}</span>
          <span className={styles.value}>
            <a href="/#" onClick={(e) => handleDownload(e, loan.contractUrl)}>
              <img src={pdf} alt="pdf" />
            </a>
          </span>
        </div>
      )}

      {(loan.contractDocId || loan.termsId) && (
        <div className={styles['payment-box']}>
          <span className={styles.title}>{t('installment-contract')}</span>
          <span className={`${styles.value} ${styles.pdf}`}>
            {loan.contractDocId && (
              <a href="/#" onClick={(e) => openTerms(e, loan.contractDocId)}>
                <img src={pdf} alt="pdf" />
              </a>
            )}

            {loan.termsId && (
              <a href="/#" onClick={(e) => openVop(e, loan.termsId)}>
                <img src={pdf} alt="pdf" />
              </a>
            )}
          </span>

          {open && (
            <PdfDialog
              open={open}
              onClose={closeDialog}
              pdfSrc={pdfSrc}
              title=""
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentTable;
