import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import flagCs from '../assets/flags/flag-cs.svg';
import flagEn from '../assets/flags/flag-en.svg';

i18n.use(initReactI18next).init({
  fallbackLng: ['cs', 'en'],
  lng: 'cs', //specifying the default language
  resources: {
    cs: {
      translations: require('./locales/cs/translations.json'),
    },
    en: {
      translations: require('./locales/en/translations.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['cs', 'en'];
//@ts-ignore we need to update the library, to fix the TS errors
i18n.fpLangs = [
  {
    code: 'cs',
    flag: flagCs,
    label: 'Český jazyk',
  },
  {
    code: 'en',
    flag: flagEn,
    label: 'English language',
  },
];


export default i18n;
