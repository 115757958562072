import { useState, FormEvent, FC } from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectProps } from '@mui/material/Select';

import Dialog from '../UI/Dialog';
import Button from '../UI/Button';
import TextEdit from '../UI/TextEdit';

import { styled } from '@mui/material/styles';
import styles from './RequestDialog.module.scss';
import { RobotImage } from '../UI/RobotImage';

const requestTypes = [
  { id: 1, text: 'Žádost o odklad splatnosti Financování o 1 měsíc' },
  { id: 2, text: 'BB' },
  { id: 3, text: 'CC' },
];

interface IRequestDialogProps {
  onClose: (value: boolean) => void;
  open: boolean;
}

const RequestDialog: FC<IRequestDialogProps> = ({ onClose, open }) => {
  const [reqType, setReqType] = useState<string | null>(null);
  const [requestTxt, setRequestTxt] = useState('');

  const handleClose = (success = false) => {
    resetDialog();
    onClose(success);
  };

  const resetDialog = () => {};

  const SelectStyled = styled((props: SelectProps) => <Select {...props} />)(
    () => ({
      '.MuiSelect-select': {
        color: '#838383',
        fontFamily: '"Nunito", sans-serif',
        fontWeight: 600,
        fontSize: '1rem',
      },

      fieldset: {
        border: '1px solid #939393',
      },
    })
  );

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <Dialog
      open={open}
      // title={t('connections-psd2-bank-connection')}
      title="Zadání požadavku"
      handleClose={handleClose}
    >
      <div className={styles['dialog-wrapper']}>
        <RobotImage />
        <form onSubmit={submitHandler}>
          <div className={styles['form-wrapper']}>
            <div className={styles['form-block']}>
              <span className={styles['form-title']}>
                {/* {t('application-bank-select')} */}
                Typ požadavku
              </span>

              <FormControl required fullWidth>
                <InputLabel id="request-type-select-label">
                  {/* {t('application-bank-option')} */}
                  Vyberte možnost
                </InputLabel>
                <SelectStyled
                  labelId="request-type-select-label"
                  // label={`${t('application-bank-option')} *`}
                  label="Vyberte možnost *"
                  value={reqType}
                  onChange={(event) => setReqType(event.target.value as string)}
                  variant='outlined'
                  >
                  {requestTypes.map((t) => {
                    return (
                      <MenuItem key={t.id} value={t.id}>
                        {t.text}
                      </MenuItem>
                    );
                  })}
                </SelectStyled>
              </FormControl>
            </div>

            <div className={styles['form-block']}>
              <span className={`${styles['form-title']} ${styles['alt']}`}>
                {/* {t('application-bank-select')} */}
                Odůvodnění požadavku
              </span>

              <FormControl required fullWidth>
                <TextEdit
                  id="request"
                  placeholder="Popište nám důvod aktivace odkladu splatnosti o jeden měsíc"
                  fullWidth={true}
                  value={requestTxt}
                  multiline={true}
                  rows={4}
                  noline
                  required
                  onChange={(value: string) => {
                    setRequestTxt(value);
                  }}
                />
              </FormControl>
            </div>

            <div className={styles['form-block']}>
              {/* <p>{t('application-psd-txt')}</p> */}
              {/* <p>{t('application-psd-txt2')}</p> */}

              <p>
                Aktivace odkladu splatnosti Financování je zpoplatněna. Po
                uhrazení aktivačního poplatku dojde k posunutí splatnosti
                Financování o 1 měsíc.
              </p>
              <p>
                Po kliknutí na tlačítkou odeslat obdržíte na Váš email pokyny k
                uhrazení poplatku za odklad splatnosti financování.
              </p>
            </div>

            <div className={styles.button}>
              <Button type="submit" className="application">
                {/* {t('application-button-continue')} */}
                Odeslat
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default RequestDialog;
