import TextField from '@mui/material/TextField';
import { FormWrapper } from '../FormWrapper';
import { TUserRegisterData, phoneRegex } from '../registrationSchema';
import { useTranslation } from 'react-i18next';
import { FC, useState, useEffect } from 'react';
import { useLoading } from '../../../../context/loading-context';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../selectors/selectors';
import { RegistrationSteps } from '../../../../constants/registrationSteps';
import Loading from '../../../UI/Loading';
import { addSnackbar } from '../../../../store/slices/snackbarsSlice';
import { AxiosError } from 'axios';
import { HttpStatusCode } from '../../../../constants/httpStatusCode';
import { APIService } from '../../../../services/api';

interface IPhoneProps {
  phone: TUserRegisterData['phone'];
  updateRegistrationData: (fields: Partial<TUserRegisterData>) => void;
  title: string;
  buttonText: string;
  moveToStep: (step: RegistrationSteps) => void;
}

export const Phone: FC<IPhoneProps> = ({
  phone,
  updateRegistrationData,
  title,
  buttonText,
  moveToStep,
}) => {
  const { currentUser } = useAppSelector((state) => state.currentUser);
  const dispatch = useAppDispatch();
  const [phoneError, setPhoneError] = useState('');
  const [leadId, setLeadId] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    try {
      const unparsedLeadId = localStorage.getItem('lead_id');
      if (unparsedLeadId) {
        setLeadId(JSON.parse(unparsedLeadId));
      }
    } catch (error) {
      dispatch(
        addSnackbar({
          variant: 'error',
          message: t('registration.errors.general-registration-error'),
        })
      );
    }
  }, []);

  const isFormValid = (): boolean => {
    let isValid = true;

    let error = '';

    if (!phone) {
      error = t('registration.validation.phone-incorrect');
      isValid = false;
    } else if (!phoneRegex.test(phone)) {
      error = t('registration.validation.phone-incorrect');
      isValid = false;
    }

    setPhoneError(error);

    return isValid;
  };

  const { loading, setLoading } = useLoading();
  const onSubmit = async () => {
    if (isFormValid()) {
      try {
        setLoading(true);

        await APIService.verifyPhone(phone, leadId, currentUser);
        setLoading(false);
        moveToStep(RegistrationSteps.Pin);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            setPhoneError(t('registration.errors.general-registration-error'));
          }
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <FormWrapper
      title={title}
      buttonText={buttonText}
      currentStep={5}
      onSubmit={onSubmit}
    >
      <TextField
        autoFocus
        type="string"
        value={phone}
        onChange={(e) =>
          updateRegistrationData({ phone: e.target.value.trim() })
        }
        size="small"
        placeholder="+420xxxxxxxxx"
        error={!!phoneError}
        helperText={phoneError}
        sx={{ width: '250px' }}
      />
      <Loading isLoading={loading} />
    </FormWrapper>
  );
};
